import consumer from "./consumer";

const testChannel = consumer.subscriptions.create(
  { channel: "TestChannel" },
  {
    received(data) {
      console.log("TestChannel - received", data);
    },
    initialized() {
      console.log("TestChannel - initialized");
    },
    connected() {
      console.log("TestChannel - connected");
    },
    disconnected() {
      console.log("TestChannel - disconnected");
    },
    rejected() {
      console.log("TestChannel - rejected");
    },
  }
);

document.addEventListener("ping", () => {
  testChannel.send({ request: "ping" });
});
